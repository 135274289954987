
// Headings
h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin: 0 0 $heading-margin;
	color: $color-title;
	font-family: $font-family-title;
	font-weight: $font-normal;
	line-height: 1.3;
}

h1,.h1 { font-size: $h1; }
h2,.h2 { font-size: $h2; }
h3,.h3 { font-size: $h3; }
h4,.h4 { font-size: $h4; }
h5,.h5 { font-size: $h5; }
h6,.h6 { font-size: $h6; }


.block-text {
	
	h1 {
		color: $color-text;
		font-size: $h6;
		font-weight: $font-bold;
		text-transform: uppercase;
		border-bottom: $border;
		padding-bottom: $block-padding;
		margin-bottom: $block-padding;

	}
}