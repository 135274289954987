
// Reset default list styles
ul, ol {
	@extend %reset-ul;
}

.highlighted-list {
	
	padding-left: $block-padding !important;

	li {
		
		font-weight: $font-bold;
		color: $color-text;
		position: relative;

		&:before {
			
			height: 8px;
			width: 8px;
			content: '';
			position: absolute;
			top: 10px;
			left: -$block-padding;
			display: block;
			border-radius: 50%;
			background: $color-primary;

		}
	}

}